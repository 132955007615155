import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { injectIntl } from "gatsby-plugin-intl-v3";
import { Location } from "@reach/router";
import { translateMessageId, languageMetadata } from "../utils/translations";
import { getSrc } from "gatsby-plugin-image";
const supportedLanguages = Object.keys(languageMetadata);

const SEO = ({ description, meta, title, image, canonicalUrl, intl }) => {
  const { site, ogImageDefault } = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    author
                    description
                    siteUrl
                    title
                }
            }
            ogImageDefault: file(
                relativePath: { eq: "favicon.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 460)
                }
            }
        }
    `
  );

  const desc = description || translateMessageId("site-description", intl);

  const siteTitle = translateMessageId("title", intl);

  const keywords = ["Hüseyin Sermet", "Pianist Hüseyin Sermet", "pianist and composer Hüseyin Sermet", "Huseyin Sermet"]
  return (
    <Location>
      {({ location }) => {
        const { pathname } = location;
        let canonicalPath = pathname;
        const firstDirectory = canonicalPath.split("/")[1];
        if (!supportedLanguages.includes(firstDirectory)) {
          canonicalPath = `/${pathname}`;
        }
        const canonical =
          canonicalUrl || `${site.siteMetadata.siteUrl}${canonicalPath}`;

        /* Set fallback ogImage based on path */
        const siteUrl = site.siteMetadata.siteUrl;
        let ogImage = getSrc(ogImageDefault);
        if (image) {
          ogImage = image;
        }
        const ogImageUrl = siteUrl.concat(ogImage);

        return (
          <Helmet
            htmlAttributes={{ lang: intl.locale }}
            title={title}
            titleTemplate={`%s | ${siteTitle}`}
            link={[{ rel: "canonical", key: canonical, href: canonical }]}
            meta={[
              {
                name: `description`,
                content: desc,
              },
              {
                name: `image`,
                content: ogImageUrl,
              },
              {
                property: `og:title`,
                content: `${title} | ${siteTitle}`,
              },
              {
                property: `og:description`,
                content: desc,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:creator`,
                content: site.siteMetadata.author,
              },
              {
                name: `twitter:site`,
                content: site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: `${title} | ${siteTitle}`,
              },
              {
                name: `twitter:description`,
                content: desc,
              },
              {
                name: `twitter:image`,
                content: ogImageUrl,
              },
              {
                property: `og:url`,
                content: siteUrl,
              },
              {
                property: `og:image`,
                content: ogImageUrl,
              },
              {
                property: `og:site_name`,
                content: `huseyinsermet.com`,
              },
              {
                property: `keyword`,
                content: keywords,
              }
            ].concat(meta)}
          >
            <meta name="robots" content="index,follow" />
            <script type="application/ld+json">
              {`
        {
          "@context": "https://schema.org",
          "@type": "Person",
          "url": "https://huseyinsermet.com",
          "name": "Pianist & Composer Huseyin Sermet",
          "logo": "https://huseyinsermet.com/favicon.png"
        }
      `}
            </script>
          </Helmet>
        );
      }}
    </Location>
  );
};

SEO.defaultProps = {
  description: ``,
  meta: [],
  image: ``,
  title: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default injectIntl(SEO);
